import { BrowserRouter, Route, Switch, Redirect  } from "react-router-dom";

import './App.css';

import Header from "./components/navbar/header.js";
import Footer from "./components/footer/footer.js";
import Landing from "./components/landingPage/landingPage.js";
import AboutUs from "./components/aboutUs/aboutUs.js";
import Homestay from "./components/homestay/homestay.js";
import Internship from "./components/internship/internship.js";
import Blog from "./components/posts/blog.js";
import PostOne from "./components/posts/post1.js";
import PostTwo from "./components/posts/post2.js";
import PostThree from "./components/posts/post3.js";
import PostFour from "./components/posts/post4.js";
import PostFive from "./components/posts/post5.js";
import PostSix from "./components/posts/post6.js";
import PostSeven from "./components/posts/post7.js";
import PostEight from "./components/posts/post8.js";
import PostNine from "./components/posts/post9.js";
import PostTen from "./components/posts/post10.js";
import FAQinternship from "./components/hosts_learner/FAQinternship.js";
import HostFAQ from "./components/hosts_learner/hostFAQ.js";
import HowItWorks from "./components/hosts_learner/howitworks.js";
import LearnerTerm from "./components/hosts_learner/learnerTerm.js";
import Privacy from "./components/hosts_learner/privacy.js";
import HostTerms from "./components/hosts_learner/hostTerms.js";
import InviteFriend from "./components/InviteFriend/inviteFriend.js";
import ContactUs from "./components/contactUs/contactUs.js";
import SignUp from "./components/signUp/signUp.js";
import Affiliates from "./components/affiliates/affiliates.js";
import NotFound from "./components/notFound/notFound.js";

//import { faTerminal } from "@fortawesome/free-solid-svg-icons";

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter basename="/">
          <Switch>
            <Route path="/" component = {Landing} exact/>
            <Route path="/aboutUs" component = {AboutUs} />
            <Route path="/internship" component = {Internship} />
            <Route path="/homestay" component = {Homestay} />
            <Route path="/blog" component={Blog} exact/>
            <Route path="/blog/post_1" component={PostOne} exact/>
            <Route path="/blog/post_2" component={PostTwo} exact/>
            <Route path="/blog/post_3" component={PostThree} exact/>
            <Route path="/blog/post_4" component={PostFour} exact/>
            <Route path="/blog/post_5" component={PostFive} exact/>
            <Route path="/blog/post_6" component={PostSix} exact/>
            <Route path="/blog/post_7" component={PostSeven} exact/>
            <Route path="/blog/post_8" component={PostEight} exact/>
            <Route path="/blog/post_9" component={PostNine} exact/>
            <Route path="/blog/post_10" component={PostTen} exact/>
            <Route path="/howitworks" component={HowItWorks} exact/>
            <Route path="/hostterms" component={HostTerms} />
            <Route path="/hostfaq" component={HostFAQ} />
            <Route path="/Terms" component={LearnerTerm} />
            <Route path="/faqInternship" component={FAQinternship} />
            <Route path="/privacy" component={Privacy}  />
            <Route path="/inviteFriend" component={InviteFriend} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/signUp" component={SignUp} />
            <Route path="/affiliates" component={Affiliates} />
            <Route path="/notfound" component={NotFound} />
            <Route render={() => <Redirect to={{pathname: "/notfound"}} />} />
          </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;

import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './notFound.css';

class NotFound extends Component{
    render(){
        return(
            <body style={{height: '800px', backgroundColor: '#eee'}}>
            <div class="container text-center" id="error" style={{paddingTop: '150px'}}>
                <svg height="150" width="150">
                    <polygon points="50,25 17,80 82,80" stroke-linejoin="round" style={{fill:'none', stroke:'#ff8a00', strokeWidth:'8'}} />
                    <text x="42" y="74" fill="#ff8a00" font-family="sans-serif" font-weight="900" font-size="42px">!</text>
                </svg>
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-icon text-warning">
                            <span class="uxicon uxicon-alert" />
                        </div>
                        <h1>File not found (404 error)</h1>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 col-md-push-3">
                    <p class="lead">If you think what you're looking for should be here, please contact the site owner.</p>
                    <a href='/'><button className="btn-block btn-secondary mt-3 mb-1 prev" style={{color: '#ff8a00'}}>Return home page</button></a>
                    </div>
                </div>
            </div>
            </body>
        )
    }
}

export default NotFound;